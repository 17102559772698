import { useEffect, useMemo, useRef, useState } from 'react';
import { UAParser } from 'ua-parser-js';
import { AGENTS, AGENT_BROWSER_NAMES, UNKNOWN_OS } from '../constants/userAgent';

const useUserAgent = () => {
  const isLoaded = useRef(false);
  const [agent, setAgent] = useState<UAParser.IResult>();

  useEffect(() => {
    if (navigator) {
      const navigatorUserAgent = UAParser(navigator.userAgent);
      setAgent(navigatorUserAgent);
      isLoaded.current = true;
    }
  }, []);

  const isMobileSafari = useMemo(
    () =>
      agent?.browser?.name === AGENT_BROWSER_NAMES.MOBILE_SAFARI &&
      agent?.os.version &&
      parseInt(agent?.os.version, 10) >= 6,
    [agent],
  );

  const isSafariIOS = useMemo(
    () => agent?.os?.name === AGENTS.IOS.name.toUpperCase() && agent?.browser?.name === AGENT_BROWSER_NAMES.SAFARI,
    [agent],
  );

  return {
    agent,
    isLoaded: isLoaded.current,
    isMobileSafari,
    isSafariIOS,
    operatingSystem: agent?.os?.name || UNKNOWN_OS,
  };
};

export default useUserAgent;
